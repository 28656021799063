<template>
  <main>
    <Questions/>
  </main>
</template>

<script>
// @ is an alias to /src
import Questions from '@/components/Questions.vue'

export default {
  name: 'AdditionalInfo',
  components: {
    Questions,
  }
}
</script>
